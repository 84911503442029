.box {
  display: flex;
  flex-wrap: wrap; /* Optional. only if you want the items to wrap */
  justify-content: center; /* For horizontal alignment */
  align-items: center; /* For vertical alignment */
  border: 1px solid lightgray;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.exportbutton {
  margin-left: auto;
  margin-right: 0px;
  margin-bottom: 20px;
}
.backbutton {
  margin-left: 0px;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
/* .card,
.btn,
.form-control {
  border-radius: 0;
} */
/* .navbar-dark .navbar-nav .nav-link {
  color: silver;
} */
/* .btn-primary {
  background-color: #01344f;
} */

/* .btn-outline-primary {
  color: #01344f;
}
.text-primary {
  color: #01344f;
}
.bg-primary {
  background-color: #01344f;
}
.badge-primary {
  color: #01344f;
}
.body-bg {
  color: #01344f;
}
.navbar-default-color {
  color: #01344f;
} */
