/* import only the necessary Bootstrap files */
// @import 'bootstrap/scss/functions';
// @import 'bootstrap/scss/variables';

// /* -------begin customization-------- */

// /* change the primary theme color to red */
// $theme-colors: (
//   primary: red
// );
// /* -------end customization-------- */

// /* finally, import Bootstrap to set the changes! */
// @import 'bootstrap';

// Override default variables before the import
//$body-bg: #000;

// $theme-colors: (
//   primary: #01344f
// );

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
